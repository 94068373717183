import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tag, TagLabel, Text } from '@chakra-ui/react'
import { useVirtualizer } from '@tanstack/react-virtual'

import {
  useKanbanRecord,
  useWorkOrder,
  useWorkOrderStation,
  useWorkOrderStationInOut,
} from '@/hooks'
import { useContainerDimensions } from '@/hooks/useContainerDimensions'

import { formatDate, formatDateWithTime } from '@/utils/Date'
import {
  useWorkListDefectColumns,
  WorkListKanbanColumns,
} from '@/utils/tableColumn'

import { NoDataWarning } from '../atoms'
import { BaseSelect } from '../atoms/select'
// import { InAndExitKanbanListTableColumnHelper } from '@/helpers/tableColumn'
import { VirlistTable } from '../organisms'

export const InAndExitRoutePage = () => {
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<{
    label: string
    value: number
  }>()
  const [selectedWorkList, setSelectedWorkList] =
    useState<WorkerOrderStationsListResponse>()
  const parentRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const selectElement = useRef<HTMLDivElement>(null)
  const { t, i18n } = useTranslation()
  const workOrderResponse = useWorkOrder(i18n.language, {})
  const workOrderStationResponse = useWorkOrderStation({
    workOrderId: selectedWorkOrder?.value,
  })

  const { query: workOrderStationInAndOutResponse } = useWorkOrderStationInOut({
    workOderStationId: selectedWorkList?.id.toString() || '1',
  })
  const stationInfo = useMemo(
    () => workOrderStationInAndOutResponse.data?.stationInfo,
    [workOrderStationInAndOutResponse.data],
  )

  const kanbanListData = useMemo(
    () =>
      workOrderStationInAndOutResponse.data?.kanbanList.sort((a, b) => {
        if (a.kanbanCode > b.kanbanCode) {
          return 1
        } else if (a.kanbanCode < b.kanbanCode) {
          return -1
        } else {
          return a.time > b.time ? 1 : -1
        }
      }),
    [workOrderStationInAndOutResponse.data],
  )

  const defectiveRecordList = useMemo(
    () => workOrderStationInAndOutResponse.data?.defectiveRecordList,
    [workOrderStationInAndOutResponse.data],
  )

  const workOrderStationList = useMemo(
    () => workOrderStationResponse.query.data,
    [workOrderStationResponse.query.data],
  )

  const workOrderOptions = useMemo(() => {
    const result = workOrderResponse.query.data
      ? workOrderResponse.query.data
          .filter((order) => order.status !== 'stock')
          .map((order) => ({
            label: order.code,
            value: order.id,
          }))
      : []
    return result
  }, [workOrderResponse.query.data])

  const wrapperRefInfo = useContainerDimensions(wrapperRef)
  const selectElementInfo = useContainerDimensions(selectElement)

  const virtualizer = useVirtualizer({
    count: workOrderStationResponse.query.data?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 68,
  })
  const listHeight = wrapperRefInfo.height - selectElementInfo.height - 10

  const handleSelect = (option: unknown) => {
    const { value, label } = option as Option
    const selectValue = Number(value)
    setSelectedWorkOrder({ label, value: selectValue })
  }
  // default select
  useEffect(() => {
    if (!workOrderStationList) return
    setSelectedWorkList(workOrderStationList[0])
  }, [workOrderStationList])

  useEffect(() => {
    if (!!workOrderOptions.length && !selectedWorkOrder) {
      handleSelect(workOrderOptions[0])
    }
  }, [workOrderOptions])

  return (
    <Box className="p-[1rem]">
      <div
        className="grid  grid-cols-1 gap-x-0  gap-y-[1rem] lg:grid-cols-5 lg:gap-x-[1rem]"
        ref={wrapperRef}
      >
        <Box className="rounded-[0.375rem] border bg-white shadow-md ">
          <div className="bg-blue-500 p-4" ref={selectElement}>
            <BaseSelect
              isSearchable={true}
              onChange={handleSelect}
              value={selectedWorkOrder}
              options={workOrderOptions}
              controlBg="white"
              controlTextColor="black"
              isDisabled={workOrderOptions.length === 0}
              placeholder={t('PleasePickThe', { what: t('Workorder') })}
            />
          </div>
          {!!workOrderStationList?.length && selectedWorkOrder?.value ? (
            <div className="relative p-4">
              <div
                ref={parentRef}
                className="w-full overflow-y-auto "
                style={{
                  height: listHeight,
                  contain: 'strict',
                }}
              >
                <div
                  style={{
                    height: virtualizer.getTotalSize(),
                  }}
                  className={`relative w-full`}
                >
                  {virtualizer
                    .getVirtualItems()
                    .map(({ key, index, size, start }) => (
                      <div
                        key={key}
                        data-index={index}
                        ref={virtualizer.measureElement}
                        className="absolute left-0 top-0 flex w-full flex-col gap-[1rem]"
                        style={{
                          transform: `translateY(${start}px)`,
                          width: '100%',
                          height: `${size}px`,
                        }}
                      >
                        <ListItem
                          item={workOrderStationList[index]}
                          selected={selectedWorkList}
                          onSelect={setSelectedWorkList}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <NoDataWarning />
          )}
        </Box>
        <div className="grid-rows-9 col-span-4 grid gap-[1rem]">
          <Box className=" row-span-1 rounded-[0.375rem] border bg-white p-4 shadow-md">
            <WorkListInfo info={stationInfo} />
          </Box>
          <Box className=" row-span-4 overflow-hidden rounded-[0.375rem] border bg-white p-4 shadow-md">
            <KanbanList
              data={kanbanListData ?? []}
              workOderStationId={String(selectedWorkList?.id)}
            />
          </Box>
          <Box className=" row-span-3 overflow-hidden rounded-[0.375rem] border bg-white p-4 shadow-md">
            <DefectList data={defectiveRecordList ?? []} />
          </Box>
        </div>
      </div>
    </Box>
  )
}

interface ListItemProps {
  item: WorkerOrderStationsListResponse
  selected: WorkerOrderStationsListResponse | undefined
  onSelect: React.Dispatch<
    React.SetStateAction<WorkerOrderStationsListResponse | undefined>
  >
}
const ListItem = ({ item, selected, onSelect }: ListItemProps) => {
  const { i18n } = useTranslation()
  const isSelectedCard = useMemo(
    () => selected?.id === item.id,
    [selected, item],
  )
  const handleClick = () => {
    onSelect(item)
  }
  return (
    <div
      className={`
      flex cursor-pointer items-center justify-between rounded-[0.25rem] border border-gray-200 p-[0.75rem] shadow-sm
      ${item.status === 'checkOut' ? 'bg-gray-100' : 'bg-white'}
      ${isSelectedCard ? 'border-[1.5px] border-[#3F51B5]' : ''}`}
      onClick={handleClick}
    >
      <p className="text-[0.875rem]">
        {i18n.language === 'vi' ? item.nameVi : item.nameZhTW}
      </p>
      {item.status === 'checkIn' && <CheckInTag />}
      {item.status === 'checkOut' && <CheckoutTag />}
    </div>
  )
}

const CheckInTag = () => {
  const { t } = useTranslation()
  return (
    <Tag variant="outline" colorScheme="green">
      <TagLabel fontSize={'0.875rem'}>{t('checkin')}</TagLabel>
    </Tag>
  )
}

const CheckoutTag = () => {
  const { t } = useTranslation()
  return (
    <Tag variant="solid" backgroundColor={'red.400'}>
      <TagLabel fontSize={'0.875rem'}>{t('checkout')}</TagLabel>
    </Tag>
  )
}
interface WorkListInfoProps {
  info: WorkerOrderStationsInfoResponse | undefined
}
const WorkListInfo = ({ info }: WorkListInfoProps) => {
  const { i18n } = useTranslation()
  const infoName = useMemo(
    () =>
      i18n.language === 'vi'
        ? info?.workOrderStationNameVi
        : info?.workOrderStationNameZhTW,
    [i18n.language, info],
  )
  const { t } = useTranslation()
  return (
    <div className="flex">
      <div className="basis-[12rem]">
        <WorkListInfoTitleBig>{infoName}</WorkListInfoTitleBig>
        <WorkListInfoText>{info?.workOrderStationCode}</WorkListInfoText>
      </div>
      <div className="flex-1">
        <WorkListInfoTitleSmall>
          {t('workOrder.station.CheckinTime')}
        </WorkListInfoTitleSmall>
        <WorkListInfoText>
          {info?.firstCheckInTime
            ? formatDateWithTime(info?.firstCheckInTime as string)
            : '-'}
        </WorkListInfoText>
      </div>
      <div className="flex-1">
        <WorkListInfoTitleSmall>
          {t('workOrder.station.CheckoutTime')}
        </WorkListInfoTitleSmall>
        <WorkListInfoText>
          {info?.lastCheckOutTime
            ? formatDateWithTime(info?.lastCheckOutTime as string)
            : '-'}
        </WorkListInfoText>
      </div>
      <div className="basis-[12rem]">
        <WorkListInfoTitleSmall>
          {t('workOrder.kanbans.ProductName')}
        </WorkListInfoTitleSmall>
        <WorkListInfoText>
          {info?.productName ? info?.productName : '-'}
        </WorkListInfoText>
      </div>
    </div>
  )
}

const WorkListInfoTitleBig = ({ children }: PropsWithChildren) => {
  return (
    <Text
      color={'black'}
      fontSize={'1.875rem'}
      fontWeight="bold"
      marginBottom={'2'}
    >
      {children}
    </Text>
  )
}
const WorkListInfoTitleSmall = ({ children }: PropsWithChildren) => {
  return (
    <Text color={'gray.400'} fontSize={'1rem'} marginBottom={'2'}>
      {children}
    </Text>
  )
}
const WorkListTitle = ({ children }: PropsWithChildren) => {
  return (
    <Text fontWeight={'bold'} fontSize={'1.125rem'} mb={2}>
      {children}
    </Text>
  )
}
const WorkListInfoText = ({ children }: PropsWithChildren) => {
  return (
    <Text color={'gray.500'} fontSize={'1.25rem'} fontWeight={600}>
      {children}
    </Text>
  )
}

interface KanbanListProps {
  data: WorkerOrderKanbanListResponse[]
  workOderStationId: string
}
const KanbanList = ({ data, workOderStationId }: KanbanListProps) => {
  const { t, i18n } = useTranslation()
  const { update: UpdateKanbanRecord } = useKanbanRecord({ workOderStationId })

  const handleConfirmTime = async (date: Date, recordId: string) => {
    await UpdateKanbanRecord.mutateAsync({
      kanbanRecordId: recordId,
      data: {
        time: formatDate(String(date), 'YYYY-MM-DD HH:mm:ss'),
      },
    })
  }

  const getRowStyle = (row: WorkerOrderKanbanListResponse) => {
    return !!row.reworkQuantity && row.reworkQuantity > 0
      ? { backgroundColor: '#f6dddd' }
      : {}
  }

  return (
    <>
      <WorkListTitle>{t('KanbanList')}</WorkListTitle>
      <VirlistTable
        columns={WorkListKanbanColumns(
          handleConfirmTime,
          workOderStationId,
          i18n.language,
        )}
        data={data}
        tableType="simple"
        containerHeight={500}
        headClass="bg-gray-50 text-gray-300 font-bold"
        getRowStyle={getRowStyle}
      />
    </>
  )
}
interface DefectListProps {
  data: WorkerOrderDefectiveListResponse[]
}
const DefectList = ({ data }: DefectListProps) => {
  const { t, i18n } = useTranslation()
  const columns = useWorkListDefectColumns(i18n.language)
  return (
    <>
      <div className=""></div>
      <WorkListTitle>{t('DefectiveRecord')}</WorkListTitle>
      <VirlistTable
        columns={columns}
        data={data}
        columnClass="align-top"
        tableType="simple"
        containerHeight={250}
        headClass="bg-gray-50 text-gray-300 font-bold"
      />
    </>
  )
}
