import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ProductStationCreateSchema } from '@/helpers/validate/productStation'

import { API } from '@/utils/API'

import {
  productionStationsBaseKeys,
  productionStationsKeys,
} from '@/constants/queryKeyFactory'

import { TranslationKeys } from '@/_types_/i18next'

import { useAPI } from './useAPI'
import { useSnackbar } from './useSnackbar'

const DEFAULT_SELECTED_PRODUCT = {
  id: undefined,
  code: '',
  name: '',
}

interface ProductStationProps {
  selectedProduct: Partial<ProductionData>
}

export const useProductStation = ({
  selectedProduct = DEFAULT_SELECTED_PRODUCT,
}: ProductStationProps) => {
  const { request } = useAPI()
  const { t } = useTranslation()
  const snackBar = useSnackbar()
  const queryClient = useQueryClient()
  const apiRoute = API.routes.productStation

  const getProductStations: QueryFunction<
    ProductStationResponse[]
  > = async () => {
    const { data } = await request<ProductStationResponse[], never, never>(
      'get',
      apiRoute.list({ productId: selectedProduct.id }),
    )
    return data
  }
  const productStationsQuery = useQuery({
    queryKey: productionStationsKeys.lists(),
    queryFn: getProductStations,
    enabled: !!selectedProduct.id,
    refetchOnWindowFocus: false,
  })

  const productStationsByIdQuery = useQuery({
    queryKey: productionStationsKeys.listById(selectedProduct.id),
    queryFn: getProductStations,
    enabled: !!selectedProduct.id,
    refetchOnWindowFocus: false,
  })

  const productStationsBaseIdsResponse = useQuery({
    queryKey: productionStationsKeys.lists(),
    queryFn: getProductStations,
    select: (data) => data.map((item) => item.productStationBaseId),
    enabled: !!selectedProduct.id,
    refetchOnWindowFocus: false,
  })

  const createProductStationsData: MutationFunction<
    void,
    { data: ProductStationCreateSchema }
  > = async ({ data }) => {
    await request('post', apiRoute.create, { data })
  }

  const createProductStationsQuery = useMutation({
    mutationKey: productionStationsKeys.create(),
    mutationFn: createProductStationsData,
    onSuccess: async () => {
      snackBar.success({
        title: t('toast.edit.success', { type: selectedProduct.code }),
      })
      await Promise.all([
        queryClient.invalidateQueries(productionStationsKeys.lists()),
        queryClient.invalidateQueries(productionStationsBaseKeys.lists()),
      ])
    },
    onError: async (error) => {
      if (error instanceof AxiosError) {
        snackBar.error({
          title:
            t('toast.edit.error', {
              type: selectedProduct.code,
            }) +
            ' ' +
            t(`api.errors.${error.response?.data.message}` as TranslationKeys),
        })
        return
      }
      snackBar.error({ title: t('Error') })
    },
    onSettled: async () => {},
  })
  const deleteProductStationsData: MutationFunction<
    void,
    { id: string }
  > = async ({ id }) => {
    await request('delete', apiRoute.delete(id))
  }

  const updateProductStationsData: MutationFunction<
    { id: string },
    { id: string; data: updateProductRequest }
  > = async ({ id, data }) => {
    await request('patch', apiRoute.update(id), { data })
    return { id }
  }

  const updateProductStationsQuery = useMutation({
    mutationKey: productionStationsKeys.update(),
    mutationFn: updateProductStationsData,
    onSuccess: async ({ id }) => {
      snackBar.success({
        title: t('toast.edit.success', { type: selectedProduct.code }),
      })
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries(productionStationsKeys.lists()),
        queryClient.invalidateQueries(productionStationsBaseKeys.lists()),
      ])
    },
  })

  return {
    query: productStationsQuery,
    queryById: productStationsByIdQuery,
    create: createProductStationsQuery,
    update: updateProductStationsQuery,
    productStationsBaseIds: productStationsBaseIdsResponse.data,
  }
}
