import { createTrackedSelector } from 'react-tracked'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { unproxify } from '@/utils/unProxy'

type SelectedProductDetailState = {
  checkInType: CheckInType
  checkOutType: CheckOutType
}
interface SelectProductState {
  selectedProduct: Partial<ProductionData>
  selectedProductDetail: Partial<ProductStationResponse>
  selectedProductDetailIndex: number | undefined
  selectStationListBase: Partial<StationListBaseData>
  setSelectedProduct: (product: ProductionData) => void
  setSelectedProductDetail: (
    product: ProductStationResponse,
    index: number,
  ) => void
  setSelectStationListBase: (product: StationListBaseData) => void
  updateSelectedProductDetail: ({
    key,
    value,
  }: {
    key: 'checkInType' | 'checkOutType'
    value: any
  }) => void
}

const SelectProductStore = create<SelectProductState>()(
  devtools(
    persist(
      (set, get) => ({
        selectedProduct: {},
        selectedProductDetailIndex: undefined,
        selectedProductDetail: {},
        selectStationListBase: {},
        setSelectedProduct: (product: ProductionData) => {
          set({ selectedProduct: unproxify(product) })
        },
        setSelectedProductDetail: (
          product: ProductStationResponse,
          index: number,
        ) => {
          set({
            selectedProductDetail: unproxify(product),
            selectedProductDetailIndex: index,
          })
        },
        setSelectStationListBase: (productStateBase: StationListBaseData) => {
          set({ selectStationListBase: productStateBase })
        },
        updateSelectedProductDetail: ({ key, value }) => {
          const productDetails = get().selectedProductDetail

          set({
            selectedProductDetail: {
              ...productDetails,
              [key]: value,
            },
          })
        },
      }),
      { name: 'SelectProduct-Store' },
    ),
    { name: 'SelectProduct Store', serialize: { options: true } },
  ),
)

/**
 * @description React custom hook which uses Zustand to manage the Left Drawer component.
 */
export const useSelectProduct = createTrackedSelector(SelectProductStore)
