import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { Box } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'

import { BaseSelect } from '@/components/atoms/select'

import { useDraggableDashboard, useSelectProduct } from '@/hooks'

import { getSelectInOptions, getSelectOutOptions } from '@/helpers/options'

import { IsEmptyObject } from '@/utils/Object'
import { unproxify } from '@/utils/unProxy'

import { productionStationsKeys } from '@/constants/queryKeyFactory'

export const DraggableDashboardSideBar = () => {
  const queryClient = useQueryClient()
  const [checkInType, setCheckInType] = useState<CheckInType>()
  const [checkOutType, setCheckOutType] = useState<CheckOutType>()
  const { t, i18n } = useTranslation()
  const { selectedProductDetail, updateSelectedProductDetail } =
    useSelectProduct()

  const { originColumns } = useDraggableDashboard()

  useEffect(() => {
    setCheckInType(selectedProductDetail.checkInType)
    setCheckOutType(selectedProductDetail.checkOutType)
  }, [selectedProductDetail.checkInType, selectedProductDetail.checkOutType])
  const handleChangeCheckInType = (
    option: SingleValue<Option>,
    key: 'checkInType' | 'checkOutType',
  ) => {
    if (!option) return

    const assignedStations =
      unproxify(originColumns.assign.items)
        .map(
          (item) =>
            (item.data as StationSectionData).stationSectionProductBaseArr,
        )
        .flat() ?? []
    const currentProductStation = [...assignedStations].map((station) => {
      const isTargetStation =
        station.id === selectedProductDetail.id &&
        station.sequence === selectedProductDetail.sequence
      return isTargetStation
        ? {
            ...station,
            [key]: option.value,
          }
        : station
    })

    queryClient.setQueriesData(
      productionStationsKeys.lists(),
      currentProductStation,
    )
    updateSelectedProductDetail({ key, value: option.value })
  }

  const selectInOptions = useMemo(
    () => getSelectInOptions(i18n.language),
    [i18n.language],
  )
  const selectOutOptions = useMemo(
    () => getSelectOutOptions(i18n.language),
    [i18n.language],
  )

  return (
    <Box className="rounded-[0.375rem] bg-white p-[0.875rem] shadow-md">
      <div className="flex items-end justify-end border-b py-[1rem]">
        <p className="text-[1.5rem] font-[600] text-gray-800">
          {i18n.language === 'vi'
            ? selectedProductDetail.nameVi
            : selectedProductDetail.nameZhTW}
        </p>
      </div>
      <h1 className="my-[1rem] text-[1rem] font-[700]">{t('InExitMode')}</h1>
      <div className=" grid  grid-cols-2 ">
        <div className="border-b border-gray-300 bg-gray-50 px-[1.5rem] py-[0.5rem] text-[0.875rem] font-[700]">
          {t('Item')}
        </div>
        <div className="border-b border-gray-300 bg-gray-50 px-[1.5rem] py-[0.5rem] text-[0.875rem] font-[700]">
          {t('Mode')}
        </div>
        <div className="flex items-center border-b border-gray-200 px-[1.5rem] py-[1rem]">
          {t('In')}
        </div>
        <div className="border-b border-gray-200 px-[1.5rem] py-[1rem]">
          <BaseSelect
            menuAlign="right"
            isSearchable={false}
            onChange={(option) =>
              handleChangeCheckInType(option, 'checkInType')
            }
            value={
              selectInOptions.find(({ value }) => value === checkInType) ?? null
            }
            options={selectInOptions}
            controlBg="white"
            controlTextColor="black"
            isDisabled={IsEmptyObject(selectedProductDetail)}
          />
        </div>
        <div className="flex items-center border-b border-gray-200 px-[1.5rem] py-[1rem]">
          {t('Exit')}
        </div>
        <div className="border-b border-gray-200 px-[1.5rem] py-[1rem]">
          <BaseSelect
            menuAlign="right"
            isSearchable={false}
            onChange={(option) =>
              handleChangeCheckInType(option, 'checkOutType')
            }
            value={
              selectOutOptions.find(({ value }) => value === checkOutType) ??
              null
            }
            options={selectOutOptions}
            controlBg="white"
            controlTextColor="black"
            isDisabled={IsEmptyObject(selectedProductDetail)}
          />
        </div>
      </div>
    </Box>
  )
}
